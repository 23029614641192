import './AdBox.css';
import React from 'react';
import { AppContext } from '../App.js'

class AdBox extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            _ad_init_done: false,
            ad_id: `reklaami_${Date.now()}`,
        };
    }

    componentWillUnmount() {
        if (window.relevantDigital && relevantDigital.destroySlots) {
            // @see https://help.relevant-digital.com/knowledge/relevantdigital.destroyslots
            relevantDigital.destroySlots();
            this.setState({
                _ad_init_done: false,
            });
            //window._ad_init_done = false;
        }
    }

    componentDidMount() {
        this.popMediaAds();
    }

    componentDidUpdate(prevProps, prevState) {
        this.popMediaAds();
    }

    injectDeviceInfoIntoPopMediaAds() {
        let elements_ok = false;
        const type = window.innerWidth < 980 ? 'mobile' : 'desktop';
        ["data-lazy-" + type + "-ad-unit-id", "data-" + type + "-ad-unit-id"].forEach((attr) => {
            Array.from(document.querySelectorAll("["+ attr + "]")).forEach((elm) => {
                elements_ok = true
                if (elm.getAttribute('data-slot-rendered') === "true") {
                    return;
                }
                elm.setAttribute(attr.replace(type + "-", ""), elm.getAttribute(attr));
                elm.setAttribute('data-slot-rendered', 'true');
            });
        });
        return elements_ok;
    }

    servePopMediaAds() {
        //if (window._ad_init_done) {
        if (this.state._ad_init_done) {
            // Already defined -> skip!
            return;
        }
        //window._ad_init_done = true;
        this.setState({
            _ad_init_done: true,
        });

        /**
         * @todo see https://help.relevant-digital.com/knowledge/instream-video-with-hbm
         * Define "video slots", the concept is similar to using <div data-ad-unit-id="??"> or calling
         *      * googletag.pubads().defineSlot([path], ..) manually - except that they doesn't correspond to any <div>.
         *      * But the ids (obtained by getSlotElementId()) can be used e.g. as the allowedDivIds parameter to loadPrebid
         *      *
         * const PRE_ROLL_IDS = relevantDigital.defineVideoSlots([
         *         { path: '/19968336/prebid_cache_video_adunit' },
         *     ]).map((s) => s.getSlotElementId());
         */
        const loadPreBid = function(divIds) {
            relevantDigital.loadPrebid({ // https://help.relevant-digital.com/knowledge/relevantdigital.loadprebid
                configId: '63e4ae374cc636ac3cc16da7', //pelaajatcom config id
                delayedAdserverLoading: true,
                manageAdserver: true,
                collapseEmptyDivs: false,
                collapseBeforeAdFetch: false,
                loadUnknownSlots: true,
                //noAdsInitRequestAll: false,
                noGpt: true,
                allowedDivIds: null, // divIds breaks..
                onSlotAndUnit: ({ slot, unit, requestAuction }) => {
                    console.info('Ad Slot', slot);
                    console.info('Prebid.JS ad unit', unit?.pbAdUnit);
                    console.info('Relevant Yield placement', unit);
                    console.info('Relevant Yield auction object', requestAuction);
                    //console.log('onSlotAndUnit() slot=' + JSON.stringify(slot) + ', unit=' + JSON.stringify(unit));
                    // document.getElementById(slot.getSlotElementId()).style.opacity = 0.2;
                    slot.waitGamSlot((gamSlot) => {
                        console.log('slot.waitGamSlot() gamSlot=' + JSON.stringify(gamSlot));
                        //gamSlot.setTargeting("some", "targeting");
                    });
                },
                onBeforeAuctionSetup: ({ auction }) => {
                    console.info('Relevant Yield auction object', auction);
                    if (auction?.adUnits?.length > 0) {
                        for (let adUnit of auction.adUnits) {
                            if (adUnit?.generalSettings?.mobileEnabled === false) {
                                adUnit.generalSettings.mobileEnabled = true;
                            }
                        }
                    }
                    console.info('Relevant Yield auction object after', auction);
                },
                onBeforeAdRequest: ({ auction }) => {
                    const bySlot = new Map();
                    auction.usedUnitDatas.forEach((unitData) => {
                        bySlot[unitData.slot] = bySlot[unitData.slot] || [];
                        bySlot[unitData.slot].push(unitData);
                    });
                    // Call .remove() on all ad unit instances except the one, per slot,
                    // that has the highest CPM bid.
                    const cpmOf = (unitData) => unitData.getHighestBid()?.cpm || 0;
                    Object.values(bySlot).forEach((arr) => {
                        arr.sort((a, b) => (
                            cpmOf(a) === cpmOf(b) ? 0 : (cpmOf(a) < cpmOf(b) ? -1 : 1)
                        ));
                        arr.slice(1).forEach((unitData) => unitData.remove());
                    });
                },
                onAuctionInitDone: ({ auction }) => {
                    console.info('Relevant Yield auction object', auction);
                    auction.usedUnitDatas.forEach((unitData) => {
                        const { pbAdUnit, adUnit, slot, code } = unitData;
                        console.info('Ad Slot', slot);
                        console.info('Prebid.JS ad unit', pbAdUnit);
                        console.info('Prebid.JS ad unit code', code);
                        console.info('Relevant Yield placement', adUnit);
                    });
                },
                onAuctionDone: ({ auction }) => {
                    console.info('Relevant Yield auction object', auction);
                },
            });
        };

        window.relevantDigital = window.relevantDigital || {};
        relevantDigital.cmd = relevantDigital.cmd || [];
        if (!relevantDigital?.loadPrebid) {
            relevantDigital.cmd.push(() => {
                loadPreBid([this.state.ad_id])
            });
        } else {
            loadPreBid([this.state.ad_id]);
        }


        //window.relevantDigital = window.relevantDigital || {};
        //relevantDigital.cmd = relevantDigital.cmd || [];
        //relevantDigital.cmd.push(function () {
            /*relevantDigital.loadPrebid({
                configId: '63e4ae374cc636ac3cc16da7', //pelaajatcom config id
                delayedAdserverLoading: true,
                manageAdserver: true,
                collapseEmptyDivs: false,
                collapseBeforeAdFetch: false,
                noGpt: true,
                allowedDivIds: null,
                onSlotAndUnit: ({ slot, unit }) => {
                    console.log('onSlotAndUnit() slot=' + JSON.stringify(slot) + ', unit=' + JSON.stringify(unit));
                    // document.getElementById(slot.getSlotElementId()).style.opacity = 0.2;
                },
            });*/
        //});
        //},
        //this._injectAdServingScript('https://popmedia-cdn.relevant-digital.com/static/tags/63e099164d6b1351e56b13a6.js');
    }

    _injectAdServingScript(scriptURL) {
        const existing_script_element = document.querySelector(`script[src*="${scriptURL}"]`);
        if (existing_script_element) {
            // Remove previous script inclusion to re-init after DOM updates to re-render elements
            existing_script_element.remove();
        }

        const script = document.createElement('script');
        script.async = true;
        script.crossorigin = "anonymous";
        script.src = scriptURL;
        script.charset = 'utf-8';
        document.head.appendChild(script);
    }

    popMediaAds() {
        const element = document.querySelectorAll('[data-desktop-ad-unit-id]');
        if (!element) {
            return;
        }
        const elements_ok = this.injectDeviceInfoIntoPopMediaAds();
        if (!elements_ok) {
            // DOM not ready, wait!
            return;
        }
        this.servePopMediaAds();
    }

    render() {
        if (!this.props?.position || !this.props?.context?.components?.reklaami) {
            return null;
        }
        const ad = this.props.context.components.reklaami[this.props.position];
        if (ad === false) {
            let adunit_id, adunit_id_mobile = null;
            // Serve PopMedia ads
            switch (this.props.position) {
                case 'article':
                    const ads = [
                        {desktop: 'pelaajatcom_smart_in_content_1_desktop', mobile: 'pelaajatcom_smart_in_content_2'},
                        {desktop: 'pelaajatcom_smart_in_content_2_desktop', mobile: 'pelaajatcom_smart_in_content_3'},
                        {desktop: 'pelaajatcom_rich_media_1_1', mobile: 'pelaajatcom_rich_media_1_1_mobile'},
                    ];
                    adunit_id = ads[Math.floor(Math.random() * ads.length)].desktop;
                    adunit_id_mobile = ads[Math.floor(Math.random() * ads.length)].mobile;
                    break;
                case 'community':
                    adunit_id = 'pelaajatcom_outstream_1_1';
                    adunit_id_mobile = 'pelaajatcom_outstream_1_1';
                    break;
                case 'sidebar':
                    adunit_id = 'pelaajatcom_boksi_300_250_1';
                    adunit_id_mobile = 'pelaajatcom_boksi_300_250_1';
                    break;
                default:
                    break;
            }
            if (adunit_id) {
                if (adunit_id_mobile) {
                    return (
                        <div className="Ad-Box" id={this.state.ad_id}>
                            <div data-desktop-ad-unit-id={'/22871113322/' + adunit_id} data-mobile-ad-unit-id={'/22871113322/' + adunit_id_mobile}></div>
                        </div>
                    );
                }
                return (
                    <div className="Ad-Box" id={this.state.ad_id}>
                        <div data-desktop-ad-unit-id={'/22871113322/' + adunit_id}></div>
                    </div>
                );
            }
        }
        if (!ad) {
            return null;
        }

        if (ad.html) {
            return (
                <div className="Ad-Box" dangerouslySetInnerHTML={{__html: ad.html}} />
            );
        }

        let ad_content = null;
        if (ad.img_url) {
            ad_content = (
                <img src={ad.img_url} alt="Mainos" />
            );
        }
        if (ad.img_link) {
            ad_content = (
                <a href={ad.img_link} target="_blank" rel="nofollow">
                    {ad_content}
                </a>
            );
        }

        return (
            <div className="Ad-Box">
                {ad_content}
            </div>
        );
    }
}

const AdBoxWithContext = (props) => {
    return (<AppContext.Consumer>
        {(context) => {
            return <AdBox {...props} context={context} />
        }}
    </AppContext.Consumer>)
};
export default AdBoxWithContext;
