import './VisitorNavigation.css';
import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro'
import {Link, useNavigate} from "react-router-dom";
import {AppContext} from "../App";

class VisitorNavigation extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            expand_notifications: false,
            unread_notifications: 0,
        };
    }

    _getApi() {
        return window.WebsiteApi;
    }

    readNotifications(event) {
        event.preventDefault();
        this.setState({
            expand_notifications: !this.state?.expand_notifications,
        });
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const notifications = this.props?.context?.notifications;
        let unread_count = 0;
        if (notifications && notifications.length > 0) {
            notifications.map((notification) => {
                const is_unread = `${notification.status}` === "0";
                if (is_unread) {
                    unread_count++;
                }
                return is_unread;
            });
        }
        if (this.state.unread_notifications !== unread_count) {
            this.setState({
                unread_notifications: unread_count,
            });
        }
    }

    getNotificationsContent() {
        const notifications = this.props?.context?.notifications;
        const hasUnread = (this.state.unread_notifications > 0);
        if (notifications && notifications.length > 0) {
            return (
                <div className="Visitor-Navigation-Notifications Has-Unread" data-count={this.state.unread_notifications} onClick={(event) => { this.readNotifications(event); }}>
                    { hasUnread ?
                        <FontAwesomeIcon icon={icon({name: 'bell', style: 'solid'})} shake style={{color: "#fff",}} />
                      :
                        <FontAwesomeIcon icon={icon({name: 'bell', style: 'regular'})} style={{color: "#fff",}} />
                    }
                </div>
            );
        }
        return (
            <div className="Visitor-Navigation-Notifications">
                <FontAwesomeIcon icon={icon({name: 'bell-slash', style: 'regular'})} style={{color: "#999",}} />
            </div>
        );
    }

    markNotificationRead(e, notification_id) {
        e.preventDefault();
        return this._getApi().markNotificationRead({
            'id': notification_id,
        }).then((data) => {
            if (data === true) {
                // Succeeded!
                this._updateNotificationData(notification_id, {status: 1});
                return;
            }
            alert('Ei onnistunut! Yritä uudestaan!'); // @todo: ERROR HANDLING
            console.dir(data); // DEBUG
        }).catch((error) => {
            alert('Ei onnistunut! Yritä uudestaan!'); // @todo: ERROR HANDLING
            console.dir(error); // DEBUG
        });
    }

    _updateNotificationData(notification_id, data) {
        let notifications = this.props.context?.notifications;
        let notifications_updated = false;
        for (let index in notifications) {
            let notification = notifications[index];
            if (!notification?.id) {
                continue;
            }
            if (`${notification.id}` !== `${notification_id}`) {
                continue;
            }
            notifications_updated = true;
            for (let key in data) {
                notifications[index][key] = data[key];
            }
            break;
        }
        if (notifications_updated) {
            this.props.context.updateContext('notifications', notifications);
        }
    }

    _getMessageNotificationIcon(icon_name) {
        if (!icon_name) {
            return null;
        }
        switch (icon_name) {
            case 'comments':
                return (
                    <FontAwesomeIcon icon={icon({name: 'comments'})}/>
                );
            case 'pencil':
                return (
                    <FontAwesomeIcon icon={icon({name: 'pencil'})}/>
                );
        }
        console.error(`Should have shown ${icon_name} but not prepared to do that..`);
        return null;
    }

    getNotificationsDropdownContent() {
        if (!this.state?.expand_notifications) {
            return null;
        }
        let notification_elements = [];
        const notifications = this.props?.context?.notifications;
        for (let notification of notifications) {
            let notification_icon = (
                <FontAwesomeIcon icon={icon({name: 'bell', style: 'solid'})} style={{color: "#fff",}} />
            );
            let notification_css_class = 'Notification-Unread';
            if (`${notification?.status}` === "1") {
                // Read
                notification_icon = (
                    <FontAwesomeIcon icon={icon({name: 'bell', style: 'regular'})} style={{color: "#999",}} />
                );
                notification_css_class = 'Notification-Read';
            }
            let notification_msg_icon = null;
            if (notification?.icon) {
                notification_msg_icon = this._getMessageNotificationIcon(notification.icon);
            }
            notification_elements.push((
                <li className={notification_css_class}>
                    {notification_icon}
                    <a href="" onClick={(e) => {
                        const navigateToLink = () => {
                            if (!notification?.link) {
                                return;
                            }
                            if (notification.link.indexOf('#') !== -1) {
                                localStorage.setItem(`anchor_uri`, notification.link);
                            }
                            this.props.navigate(notification.link.replace(/#.*$/, ''));
                        };
                        this.setState({
                            expand_notifications: false, // Hide dropdown
                        });
                        if (`${notification?.status}` !== "1") {
                            this.markNotificationRead(e, notification.id).then(navigateToLink);
                            return;
                        }
                        navigateToLink();
                    }}>
                        {notification_msg_icon}{notification.msg}
                    </a>
                </li>
            ));
        }

        return (
            <div className="Visitor-Navigation-Notifications-Dropdown">
                <ul>
                    {notification_elements}
                </ul>
            </div>
        );
    }

    render() {
        const visitor = this.props?.context?.visitor;
        if (!visitor || !visitor.anonymous_name) {
            return (
                <div />
            )
        }
        let alias = visitor.anonymous_name;
        if (visitor.account && visitor.account.username) {
            alias = visitor.account.username;
        }
        let avatar = (<FontAwesomeIcon icon={icon({name: 'user'})} />);
        if (visitor?.account?.img_thumb) {
            avatar = (<img src={visitor.account.img_thumb} alt={`Käyttäjän avatar: ${alias}`} className="User-Profile-Avatar" />);
        }
        return (
            <div className="Visitor-Navigation">
                <Link className="UserModal-link" to="/pelaaja">
                    {avatar}
                    <small>{alias}</small>
                </Link>
                {this.getNotificationsContent()}
                {this.getNotificationsDropdownContent()}
            </div>
        );
    }
}

const VisitorNavigationWithContext = (props) => {
    const navigate = useNavigate();
    return (<AppContext.Consumer>
        {(context) => {
            return <VisitorNavigation {...props} navigate={navigate} context={context} />
        }}
    </AppContext.Consumer>)
};

export default VisitorNavigationWithContext;
