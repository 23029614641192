import './PageCommon.css';
import './NewsPage.css';
import React from 'react';
import ContentColumn from "../layout/ContentColumn";
import SideBar from "../layout/SideBar";
import Loading from "../Loading";
import CommunityBox from "../boxes/CommunityBox";
import {AppContext} from "../App";
import { useParams } from 'react-router';
import {Link, useNavigate} from 'react-router-dom';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {icon} from "@fortawesome/fontawesome-svg-core/import.macro";
import ArticleComponent from "./StaticArticleComponent";
import NotFound from "../NotFound";
import AdBox from "../boxes/AdBox";
import Tools from "../tools/Tools";
import CommunityPickBox from "../boxes/CommunityPickBox";
import TagBox from "../boxes/TagBox";


export class NewsPage extends React.Component {
    resource_type = 'news';
    initializing = true;

    constructor(props) {
        super(props);
        this.state = {
            'is_loading': true,
            is_community_action_loading: false,
            'article': {},
            'article_not_found': false,
            'article_error': false,
            'articles': [],
            'index': this.props?.index,
            'uri': this.props?.uri,
        };
        if (Object.getPrototypeOf(this) === NewsPage.prototype) {
            this.componentDidUpdate({}, {}, {}); // Force data query only when not called via super()
        }
        window._openUri = (e, uri) => {
            e.preventDefault();
            this.props.navigate(uri);
        };
    }

    _isTagIndex() {
        return this.getResourceType().indexOf('tag:') !== -1;
    }

    _getIndexData() {
        if (this._isTagIndex()) {
            // Tag box is used, not news index method
            this.setState({
                'is_loading': false,
            });
            this.state.is_loading = false;
            return;
        }

        this.setState({
            'is_loading': true,
            'article': {}, // Reset article data when returning to index
            'article_not_found': false,
            'article_error': false,
        });
        return this._getApi().getResourceIndex(this.getResourceType()).then((data) => {
            if (data?.response?.status > 299) {
                // An error occurred
                this.setState({
                    'article_not_found': !!data?.not_found,
                    'is_loading': false,
                    'article_error': true,
                });
                return data;
            }
            this.setState({
                'is_loading': false,
                'articles': data,
            });
            return data;
        });
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (!this.initializing && this.state.is_loading) {
            // Already loading.. ignore (will get back here once loading completes)
            return;
        }
        const cleanUri = (uri) => {
            if (!uri) {
                return uri;
            }
            return uri.replace(/\?[^#]+/, '').replace(/#.*$/, ''); // Remove #state, if there's one; also, remove query parameters
        };
        const uri = cleanUri(this.props?.uri);
        const uri_changed = (uri !== cleanUri(prevProps?.uri));
        if (uri_changed) {
            if (this.props?.index) {
                this._getIndexData();
            } else {
                this._getArticleData(uri);
            }
        }
        if (this.initializing) {
            this.initializing = false;
        }
        window._onPageChange(this, uri, true);
    }

    _getFirstParagraphFromArticle() {
        if (this?.state?.article?.lead_paragraph) {
            const tags_to_remove = [/(<\/?[A-Za-z]+[^>]*?>)/g, "\n", "\r", "\t"];
            let lead_paragraph = this.state.article.lead_paragraph;
            for (const tag_to_remove of tags_to_remove) {
                lead_paragraph = lead_paragraph.replace(tag_to_remove, '');
            }
            return lead_paragraph;
        }
        // Fallback, try to dig the first paragraph
        const article = this?.state?.article;
        if (!article?.content) {
            return null;
        }
        let htmlString = `<div>${article.content}</div>`;
        try {
            let doc = (new DOMParser()).parseFromString(htmlString, 'text/html');
            let firstParagraph = doc.querySelector('p');
            return firstParagraph ? firstParagraph.textContent : null;
        } catch (e) {
            console.error('Failed to extract first paragraph from article!', e);
        }
    }

    getPageMetadata() {
        if (this.state?.index) {
            return {
                title: this.getResourceTypeDisplayName(2),
                ogDesc: this.getResourceTypeDisplayName(2) + ' -osio',
                ldType: 'CollectionPage',
                excludeFromIndex: false,
            }; // @todo: COULD IMPLEMENT "hasPart" FOR LD+JSON
        }
        const article = this?.state?.article;
        let ld_type; // ReportageNewsArticle, AnalysisNewsArticle, OpinionNewsArticle, ReviewNewsArticle, BackgroundNewsArticle, AdvertiserContentArticle, SatiricalArticle,
        switch (article.type) {
            case 'article':
                ld_type = 'WebPage';
                break;
            case 'news':
                ld_type = 'NewsArticle';
                break;
            case 'team':
            case 'team:player':
            case 'tournament':
            case 'match':
                ld_type = 'ItemPage';
                break;
        }
        return {
            title: article?.topic,
            img: article?.img,
            imgWidth: article?.img_width,
            imgHeight: article?.img_height,
            thumb: article?.thumb_img,
            ogDesc: this._getFirstParagraphFromArticle(),
            createdTs: article?.created_ts,
            publishedTs: article?.published_ts,
            updatedTs: article?.updated_ts,
            categories: article?.categories,
            authorName: article?.author?.name,
            authorImg: article?.author?.img,
            authorLd: article?.author?.creator?.ld,
            ldType: ld_type,
        };
    }

    _getApi() {
        return window.WebsiteApi;
    }

    likeArticle(event) {
        const article = this.state?.article;
        if (!event || !event.target) {
            return false;
        }
        event.preventDefault();
        this.setState({
            'is_community_action_loading': true,
        });

        this._getApi().likeResource({
            'resource_id': article.id,
            'comment_id': null,
        }).then((data) => {
            this.setState({
                'is_community_action_loading': false,
                'article': data,
            });
        }).catch((error) => {
            this.setState({
                'is_community_action_loading': false,
            });
            const error_msg = error?.response?.data?.message || 'Äh, jotain meni vikaa, yritä uudestaan!';
            alert(error_msg); // @todo BETTER ERROR UX
        });
        return false;
    }

    dislikeArticle(event) {
        const article = this.state?.article;
        if (!event || !event.target) {
            return false;
        }
        event.preventDefault();
        this.setState({
            'is_community_action_loading': true,
        });

        this._getApi().dislikeResource({
            'resource_id': article.id,
            'comment_id': null,
        }).then((data) => {
            this.setState({
                'is_community_action_loading': false,
                'article': data,
            });
        }).catch((error) => {
            this.setState({
                'is_community_action_loading': false,
            });
            const error_msg = error?.response?.data?.message || 'Äh, jotain meni vikaa, yritä uudestaan!';
            if (error_msg === 'VISITORS_NOT_ALLOWED_TO_DISLIKE') {
                localStorage.setItem(`signup_return_uri`, article.link); // Save return URL
                this.props.navigate('/pelaaja');
                return;
            }
            alert(error_msg); // @todo BETTER ERROR UX
        });
        return false;
    }

    _getArticleData(resource_id) {
        if (!resource_id) {
            console.error('No resource_id!');
            return;
        }
        this.setState({
            'is_loading': true,
            'article': {},
            'article_not_found': false,
            'article_error': false,
        });
        // @todo: REFACTOR COMPONENT UPDATED
        // @todo: resource_id SUCCESFUL LOADING SHOULD SET A FLAG PREVENTING FURTHER REQUESTS (FOR X AMOUNT OF TIME)
        return this._getApi().getResource(resource_id).then((data) => {
            if (data?.not_found || data?.response?.status > 299) {
                // An error occurred
                this.setState({
                    'article_not_found': !!data?.not_found,
                    'is_loading': false,
                    'article_error': true,
                });
                return data;
            }
            if (data?._redirect && data?.link) {
                if (data.link === window.location.pathname) {
                    return data; // Same URI, do not redirect to avoid loops..
                }
                // Resource is accessed by old URI, redirect!
                window.location.href = data.link;
                //this.props.navigate();
                return data;
            }
            this.setState({
                'is_loading': false,
                'article': data,
            });
            return data;
        }).catch((err) => {
            console.error('Failed to load resource: ', err);
            this.setState({
                'article_not_found': !!err?.not_found,
                'article_error': true,
                'is_loading': false,
            });
            return err;
        });
    }

    _getArticle() {
        return this.state?.article;
    }

    getArticleImage() {
        const article = this._getArticle();
        if (!article.img) {
            return;
        }
        let img_width = null;
        let img_height = null;
        let styles = {};
        if (parseInt(article?.img_width, 10) > 0) {
            img_width = parseInt(article.img_width, 10);
            styles = {width: img_width + 'px'};
        }
        if (parseInt(article?.img_height, 10) > 0) {
            img_height = parseInt(article.img_height, 10);
        }
        let srcset = [];
        if (article.img.indexOf('/8192/') !== -1) {
            srcset.push(`${article.img} 8192w`);
            srcset.push(`${article.img.replace('/8192/', '/2048/')} 2048w`);
            srcset.push(`${article.img.replace('/8192/', '/1024/')} 1024w`);
        } else if (article.img.indexOf('/2048/') !== -1) {
            srcset.push(`${article.img} 2048w`);
            srcset.push(`${article.img.replace('/2048/', '/1024/')} 1024w`);
        } else if (article.img.indexOf('/1024/') !== -1) {
            srcset.push(`${article.img} 1024w`);
        }
        if (article.img.indexOf('/original/') !== -1) {
            srcset.push(`${article.img} ${img_width}w`);
        } else if (img_width >= 512) {
            srcset.push(`${article.img.replace('/8192/', '/512/').replace('/2048/', '/512/').replace('/1024/', '/512/').replace('/original/', '/512/').replace('/128/', '/original/')} 512w`);
        }

        if (window.innerWidth <= 2048) {
            if (img_width > 1024 && window.innerWidth > 1024) {
                article.img = article.img.replace('/8192/', '/2048/');
            } else {
                article.img = article.img.replace('/8192/', '/1024/');
                article.img = article.img.replace('/2048/', '/1024/');
            }
        } else if (window.innerWidth <= 800) {
            article.img = article.img.replace('/1024/', '/512/');
            article.img = article.img.replace('/2048/', '/512/');
            article.img = article.img.replace('/8192/', '/512/');
            article.img = article.img.replace('/128/', '/original/');
        }

        if (article.img && article.img.indexOf('?light') !== -1) {
            styles.background = '#fff';
        }

        const headerImageWrapperStyles = { '--article-header-img': 'url("' + article.img + '")' };
        const sizes = '(min-width: 768px) ' + img_width + 'px, 512px'; // Show large on large screens, small on small screens
        srcset = srcset.join(', ');
        return (
            <div className="Content-Header-Image" style={headerImageWrapperStyles}>
                <img src={article.img} srcset={srcset} fetchpriority="high" width={img_width} height={img_height} sizes={sizes} style={styles} alt={'Kansikuva artikkelille: ' + article.topic} />
            </div>
        );
    }

    _getCommunityBox() {
        const article = this._getArticle();
        if (article?.community === 'disabled') {
            // Community box disabled for the page
            return null;
        }
        return (
            <CommunityBox article={article} />
        );
    }

    getArticleInfo() {
        const article = this._getArticle();
        if (!article || article?.community === 'disabled') {
            return (<div />)
        }
        let author_img = null;
        if (article.author?.img) {
            author_img = (
                <div className="Content-Author-Image">
                    <img src={article.author.img} alt={`Kirjoittajan avatar: ${article.author?.name || 'TOIMITUS'}`} />
                </div>
            );
        }
        let author_info = null;
        if (article.show_author_info) {
            author_info = (
                <div className="Content-Author-N-Date" onClick={(e) => {
                    e.preventDefault();
                    const author_url = article?.author?.creator?.uri;
                    if (author_url) {
                        this.props.navigate(author_url);
                    }
                }}>
                    {author_img}
                    {article.author?.name}
                    <br/>
                    {article.published_at}
                </div>
            );
        }

        return (
            <div className="col-md-2">
                {author_info}

                <div className="Content-Community">
                    <FontAwesomeIcon icon={icon({name: 'comment'})}/> {article.comments}<br />
                    <FontAwesomeIcon icon={icon({name: 'thumbs-up'})} /> {article.likes} vs. <FontAwesomeIcon icon={icon({name: 'thumbs-down'})} /> {article.dislikes}

                    <div className="Community-Actions">
                        <button aria-label="Anna jutulle yläpeukku" onClick={ (event) => { this.likeArticle(event); } } disabled={this.state.is_loading || article?.own_interaction > 0 || this.state.is_community_action_loading}>
                            <FontAwesomeIcon icon={icon({name: 'thumbs-up'})} />
                        </button>
                        <button aria-label="Anna jutulle alapeukku" onClick={ (event) => { this.dislikeArticle(event); } } disabled={this.state.is_loading || article?.own_interaction < 0 || this.state.is_community_action_loading}>
                            <FontAwesomeIcon icon={icon({name: 'thumbs-down'})} />
                        </button>
                    </div>
                </div>
            </div>
        ); // <FontAwesomeIcon icon={icon({name: 'eye'})} /> {article.views}<br />
    }

    getResourceTypeDisplayName(form) {
        const forms = ['Artikkeli', 'Artikkelia', 'Artikkelit'];
        return forms[form];
    }

    openArticle(e, article) {
        e.preventDefault();
        if (article?.link) {
            this.props.navigate(article.link);
        }
    }

    _getArticlesList() {
        const feed = this.state.articles;

        let feed_items = [];
        for (const feed_item of feed) {
            let author = '';
            if (feed_item?.author?.img) {
                author = (
                    <div className="Feed-Author-Column" onClick={(e) => {
                        e.preventDefault();
                        const author_url = feed_item.author.creator?.uri;
                        if (author_url) {
                            this.props.navigate(author_url);
                        }
                    }}>
                        <div>
                            <img src={feed_item.author.img} alt={`Kirjoittajan avatar: ${feed_item.author.name}`} title={feed_item.author.name} />
                        </div>
                        <strong>{feed_item.author.name}</strong>
                    </div>
                );
            }

            let item_img = '';
            if (feed_item?.img || feed_item?.thumb_img) {
                item_img = (
                    <img src={feed_item?.thumb_img || feed_item?.img} alt={`Kansikuva: ${feed_item.topic}`} title={feed_item.topic} />
                );
            }

            feed_items.push((
                <div className="Feed-Entry">
                    {author}
                    <div className="Feed-Topic-Column">
                        <div className="Feed-Item-Image" onClick={(e) => { this.openArticle(e, feed_item); }}>
                            {item_img}
                        </div>
                        <Link to={feed_item.link}>
                            {feed_item.topic}
                        </Link>
                    </div>
                    <div className="Feed-Stats">
                        <FontAwesomeIcon icon={icon({name: 'thumbs-up'})} /> {feed_item.likes} <FontAwesomeIcon icon={icon({name: 'thumbs-down'})} /> {feed_item.dislikes} <FontAwesomeIcon icon={icon({name: 'comment'})} /> {feed_item.comments}
                    </div>
                </div>
            ));
        }

        return (
            <div className="Content-Wrapper Common-Index">
                <h2 className="Title">{this.getResourceTypeDisplayName(2)}</h2>
                <div className="Feed">
                    {feed_items}
                </div>
            </div>
        );
    }

    _getTagBox() {
        return (
            <div className="Content-Wrapper Common-Index">
                <TagBox tag={this.props.uri} fullpage={true} />
            </div>
        );
    }

    getContentColumnContents() {
        if (this.props?.index) {
            if (this._isTagIndex()) {
                return this._getTagBox();
            }
            return this._getArticlesList();
        }

        const article = this._getArticle();
        if (!article || !article.type) {
            if (this.state.article_not_found) {
                // ..make sure "noindex" is defined when 404
                Tools.declarePageNoIndexToRobots();
                return (<NotFound />);
            }
            return (
                <div>
                    Voi rähmä! {this.getResourceTypeDisplayName(1)} ei voitu ladata!
                </div>
            );
        }

        let title = null;
        if (article.show_topic) {
            title = (
                <h1>{article.topic}</h1>
            );
            if (article?.type === 'news') {
                // Append ad to news articles
                title = (
                    <>
                        {title}
                        <AdBox position="article" />
                    </>
                );
            }
        }

        return (
            <div className="Content-Wrapper">
                {this.getArticleImage()}

                {this.getArticleInfo()}

                <div className="Content-Fold col-md-10">
                    {title}
                    <ArticleComponent article={article} />
                </div>
                <CommunityPickBox position="news" />
                {this._getCommunityBox()}
            </div>
        );
    }

    _isCurrentArticleLoaded(uri) {
        if (!uri) {
            return false;
        }
        uri = uri.startsWith('/') ? uri : '/' + uri;
        if (this.state?.article?.link && decodeURIComponent(this.state.article.link).indexOf(uri) !== -1) {
            return true;
        }
        return false;
    }

    getResourceType(uppercase_first) {
        if (uppercase_first) {
            return this.resource_type.charAt(0).toUpperCase() + this.resource_type.slice(1);
        }
        return this.resource_type;
    }

    getResourceTypeCssClassBase() {
        const resource_type_class = this.getResourceType(true);
        return "Content Common-Page " + resource_type_class + "-Page";
    }

    getContentColumnLoading() {
        return (
            <div>
                <Loading/>
            </div>
        );
    }

    render() {
        if (this.initializing || this.state.is_loading) {
            return (
                <div className={this.getResourceTypeCssClassBase()}>
                    <ContentColumn content={this.getContentColumnLoading()}/>
                    <SideBar />
                </div>
            );
        }
        if (this.resource_type === 'sidebar' && this.state?.article?.type === 'sidebar') {
            // Sidebar preview
            return (
                <div className={this.getResourceTypeCssClassBase()}>
                    <ContentColumn content="" />
                    <SideBar resources={[this.state.article]} categories={this.state?.article?.categories}/>
                </div>
            );
        }
        if (this.state?.article?.sidebar_ids === "DISABLED") {
            return (
                <div className={this.getResourceTypeCssClassBase()}>
                    <ContentColumn content={this.getContentColumnContents()} />
                </div>
            );
        }
        return (
            <div className={this.getResourceTypeCssClassBase()}>
                <ContentColumn content={this.getContentColumnContents()}/>
                <SideBar resources={this.state?.article?.sidebar} categories={this.state?.article?.categories} />
            </div>
        );
    }
}

const NewsWithContext = (props) => {
    const { uri } = useParams();
    const navigate = useNavigate();
    return (<AppContext.Consumer>
        {(context) => {
            return <NewsPage {...props} navigate={navigate} context={context} uri={uri} />
        }}
    </AppContext.Consumer>)
};
export default NewsWithContext;

