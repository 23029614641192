import './ImageUploadModal.css';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import {AppContext} from "../App";

class ImageUploadModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            'msg': null,
            'is_loading': false,
        };
    }

    _getApi() {
        return window.WebsiteApi;
    }

    _getVisitor() {
        return this.props?.context?.visitor;
    }


    _getMessageToDisplay() {
        let msg = null;
        if (this.state?.msg?.txt) {
            msg = (
                <div className={this.state.msg.className}>
                    {this.state.msg.txt}
                </div>
            )
        }
        return msg;
    }

    onUpload(event) {
        this.setState({
            'is_loading': true,
            'msg': null,
        });
        const files = this.refs?.file?.files;
        if (!files) {
            const error_msg = 'Äh, jotain meni vikaa, yritä uudestaan!'; //data?.response?.data?.message || 'Äh, jotain meni vikaa, yritä uudestaan!';
            this.setState({
                'is_loading': false,
                'msg': {
                    'txt': error_msg,
                    'className': 'Message-Failure'
                },
            });
            return;
        }
        this._getApi().uploadMedia(files[0], this.props?.purpose).then((data) => {
            if (this.refs?.file?.value) {
                this.refs.file.value = ''; // Reset file upload input
            }
            //console.dir(data); // DEBUG
            if (data?.url) {
                this.setState({
                    'img_preview': data.url,
                });
            }

            this.setState({
                'is_loading': false,
                'msg': {
                    'txt': 'HERMEETTISTÄ! KUVA ON TALLENNETTU!',
                    'className': 'Message-Successful'
                },
            });
            if (window._forceSessionRefresh) {
                window._forceSessionRefresh().then(() => {
                    this.props.navigate('/pelaaja');
                }); // User avatar changed, force to refresh session data and redirect then to profile
            }
        }); // @todo: HANDLE ERRORS
    }

    previewImage(event) {
        const files = event.target?.files;
        if(!files?.length) {
            this.setState({
                'img_preview': null,
            });
            return;
        }
        this.setState({
            'img_preview': URL.createObjectURL(files.item(0)),
        });
    }

    getImageUploadForm() {
        const visitor = this._getVisitor();
        const account = visitor?.account;
        if (!account) {
            return (<div />)
        }
        let preview_img = null;
        if (this.state?.img_preview) {
            preview_img = (
                <div>
                    <img src={this.state.img_preview} width="320" />
                </div>
            )
        }
        let title = 'Valitse kuva';
        if (this.props?.purpose === 'avatar') {
            title = 'Valitse uusi avatarisi laitteeltasi'
        }
        return (
            <>
                <div className="User-Profile-Container">
                    <div className="Left-Column">
                        {this._getMessageToDisplay()}
                    </div>
                    <div className="Middle-Column">
                        <h2>{title}</h2>
                        {preview_img}
                        <input className="In-Place-Button" type="file" accept="image/*" name="file" ref="file" disabled={this.state.is_loading}
                               onChange={(event) => {
                                   this.previewImage(event);
                               }}/>
                        <button className="In-Place-Button" onClick={(event) => {
                            this.onUpload(event);
                        }} disabled={this.state.is_loading}>Lataa
                        </button>
                        <button className="In-Place-Button" onClick={(e) => {
                            e.preventDefault();
                            this.props.navigate('/pelaaja');
                        }}>Takaisin</button>
                    </div>
                    <div className="Right-Column">
                        <h3>Muistathan</h3>
                        <ul>
                            <li>Sinulla on oltava tekijänoikeudet tai lupa kuvan käyttöön</li>
                            <li>Kuvan on oltava yhteisösääntöjen mukainen eikä se saa olla lain tai hyvänmaun vastainen</li>
                        </ul>
                    </div>
                </div>
            </>
        )
    }

    render() {
        const visitor = this._getVisitor();
        if (!visitor || !visitor.id) {
            return (
                <div>
                    Et ole kirjautunut sisään?!
                </div>
            );
        }

        return (
            <div className="Modal-Wrapper Common-Modal" onClick={() => this.props.navigate('/pelaaja')}>
                <div className="ImageUploadModal UserModal" onClick={e => e.stopPropagation()}>
                    {this.getImageUploadForm()}
                </div>
            </div>
        );
    }
}

function WithNavigateAndContext(props) {
    let navigate = useNavigate();
    return (<AppContext.Consumer>
        {(context) => {
            return <ImageUploadModal {...props} navigate={navigate} context={context} />
        }}
    </AppContext.Consumer>)
}

export default WithNavigateAndContext;