import './App.css';
import React from 'react';
import {createBrowserRouter, RouterProvider} from "react-router-dom";

import * as Comlink from 'comlink'
import Cookies from "universal-cookie";
import Page from "./Page";
import Loading from "./Loading";
import HomePage from "./pages/HomePage";
import CommunityIndexPage from "./pages/CommunityIndexPage";
import TournamentIndexPage from "./pages/TournamentIndexPage";
import ErrorPage from "./pages/ErrorPage";
import NewsPage from "./pages/NewsPage";
import UserModal from "./modals/UserModal";
import ImageUploadModal from "./modals/ImageUploadModal";
import ArticlePage from "./pages/ArticlePage";
import ViewUserModal from "./modals/ViewUserModal";
import PelShop from "./pages/PelShop";
import TagPage from "./pages/TagPage";
import SidebarPreviewPage from "./pages/SidebarPreviewPage";
import TournamentPage from "./pages/TournamentPage";
import TournamentMatchPage from "./pages/TournamentMatchPage";
import TournamentTeamPage from "./pages/TournamentTeamPage";
import NewsIndexPage from "./pages/NewsIndexPage";
import TournamentPlayerPage from "./pages/TournamentPlayerPage";
import Tools from "./tools/Tools";
import ViewCreatorModal from "./modals/ViewCreatorModal";

const AppContext = React.createContext({});
export { AppContext };

const websiteBaseUrl = process.env.REACT_APP_BASE_URL || '/';

let onMouseOverListenerRef;

class App extends React.Component {
    registerUpdateListener() {
        if (this.update_timer) {
            clearTimeout(this.update_timer);
        }
        this.update_timer = setInterval(() => {
            this.receiveUpdates();
        }, this.update_timer_delay);
    }

    componentDidMount() {
        this.registerUpdateListener();
        onMouseOverListenerRef = this.onMouseOver.bind(this);
        window.document.addEventListener('mouseover', onMouseOverListenerRef);
    }

    componentWillUnmount() {
        if (this.update_timer) {
            clearTimeout(this.update_timer);
        }

        if (onMouseOverListenerRef) {
            window.document.removeEventListener('mouseover', onMouseOverListenerRef);
        }
    }

    onMouseOver(e, delay_finished) {
        if (e?.target?.tagName !== 'IMG') {
            return;
        }
        if (!e.target.classList.contains('Peak-View')) {
            return;
        }
        let parentLink = e.target.closest('a');
        const full_size_img_url = parentLink?.href;
        if (!full_size_img_url) {
            return;
        }
        const thumbnailOnMouseOut = (e) => {
            if (e.target.previewTimer) {
                clearTimeout(e.target.previewTimer);
            }
        };
        e.target.addEventListener('mouseout', thumbnailOnMouseOut);
        if (e.target.previewTimer) {
            clearTimeout(e.target.previewTimer);
        }
        if (!delay_finished) {
            const preloadImage = new Image();
            preloadImage.src = full_size_img_url;
            e.target.previewTimer = setTimeout(() => {
                this.onMouseOver(e, true);
            }, 500);
            return;
        }
        // Mouse movement stopped over the item and lasted >=500ms -> show preview


        const previewWidth = 512;
        const imgRect = e.target.getBoundingClientRect();
        let leftPos = imgRect.left;
        let topPos = imgRect.top + window.scrollY + imgRect.height + 2;
        const screenWidth = window.innerWidth;
        const screenHeight = window.innerHeight;
        if (leftPos + previewWidth > screenWidth) {
            // Adjust to fit within the screen (i.e. prevent overflow on right side of the screen)
            leftPos = screenWidth - previewWidth - 20;
        }
        let previewDiv = document.createElement('div');
        previewDiv.style.position = 'absolute';
        previewDiv.style.minWidth = `${previewWidth}px`;
        previewDiv.style.width = `${previewWidth}px`;
        previewDiv.style.height = '200px';
        previewDiv.style.left = `${leftPos}px`;
        previewDiv.style.top = `${topPos}px`;
        previewDiv.style.border = '1px solid black';
        previewDiv.style.zIndex = "1000";
        previewDiv.style.overflow = 'hidden';
        previewDiv.style.background = "rgba(255, 255, 255, 0.98)";
        previewDiv.style.boxShadow = "2px 2px 5px #666";
        previewDiv.style.cursor = 'pointer';
        previewDiv.onclick = (e) => {
            if (window._peakViewOnClick) {
                window._peakViewOnClick(e);
                previewDiv.remove(); // Destroy preview on click
                return false;
            }
            window.open(full_size_img_url, '_blank');
        };
        previewDiv.addEventListener('contextmenu', (e) => {
            e.preventDefault();
            previewDiv.remove(); // Destroy preview on right click
            return false;
        }, false);

        let onMouseOutListener;
        onMouseOutListener = () => {
            previewDiv.remove();
            window._activePeakView = null;
        };
        let largerImg = document.createElement('img');
        largerImg.src = full_size_img_url;
        if (parentLink.getAttribute('data-image-id')) {
            largerImg.setAttribute('data-image-id', parentLink.getAttribute('data-image-id'));
        }
        largerImg.style.width = '512px';
        largerImg.onload = () => {
            previewDiv.style.height = largerImg.clientHeight + 'px';
            if (topPos + largerImg.clientHeight + window.scrollY > screenHeight) {
                // Adjust to fit within the screen (i.e. prevent overflow on right side of the screen)
                topPos = screenHeight - largerImg.clientHeight - 20;
                previewDiv.style.top = `${topPos}px`;
            }
            largerImg.addEventListener('mouseout', onMouseOutListener);
            previewDiv.removeEventListener('mouseout', onMouseOutListener);
        };
        largerImg.onerror = () => {
            previewDiv.style.height = '20px';
            previewDiv.style.width = '20px';
        };

        previewDiv.appendChild(largerImg);
        document.body.appendChild(previewDiv);
        if (window?._activePeakView) {
            // Only one preview visible at a time
            window._activePeakView.remove();
        }
        window._activePeakView = previewDiv;
        previewDiv.addEventListener('mouseout', onMouseOutListener);
    }

    acquireSession = () => {
        const cookies = new Cookies();
        return this.api.acquireSession().then((data) => {
            if (data && data.id && data.CSRF_TOKEN) {
                cookies.set('PL_SESSION', data.id, { path: '/' });
                cookies.set('PL_SESSION_CSRF', data.CSRF_TOKEN, { path: '/' });
            }

            this.setState({
                visitor: data,
                is_initialized: true
            });
            this.state.is_initialized = true;
            this.state.visitor = data;

            /*if (window.gtag && data?.account?.id) {
                gtag('config', 'G-K0JS756DKW', {
                    'user_id': data?.account?.id
                }); // Report user id
            }*/
        });
    };

    updateSession = (user_data) => {
        this.setState({
            visitor: user_data,
        });
    };

    updateImageSliders() {
        window.setupImageSliderUpdateTimer = () => {
            if (window.updateSliders_timeout) {
                clearInterval(window.updateSliders_timeout);
            }
            window.updateSliders_timeout = setInterval(window.updateSliders, 15000);
        };
        window.updateSliders = (initial_run) => {
            if (window._contentEditor_active) {
                return; // Ignore when editor is open
            }
            const main = document.querySelector('main');
            const sliders = main.querySelectorAll('div.Dynamic-Image-Slider');
            const filtered_sliders = Array.from(sliders).filter(element => {
                return element.closest('.ck-editor') === null;
            });
            for (const slider of filtered_sliders) {
                const slider_first_image = slider.querySelector('li');
                let slider_image_active = slider.querySelector('li.Current-Slider-Image');
                let next_image;
                if (!slider_image_active) {
                    next_image = slider_first_image;
                } else {
                    if (initial_run) {
                        return;
                    }
                    next_image = slider_image_active.nextSibling;
                    if (!next_image) {
                        next_image = slider_first_image;
                    }
                    slider_image_active.style.display = 'none';
                    slider_image_active.className = '';
                    slider_image_active.onclick = null;
                }
                next_image.style.display = 'block';
                next_image.className = 'Current-Slider-Image';
                next_image.onclick = (e) => {
                    e.preventDefault();
                    window.setupImageSliderUpdateTimer();
                    window.updateSliders();
                };
                next_image.style.cursor = 'pointer';
            }
        };
        window.setupImageSliderUpdateTimer();
    }

    receiveUpdates = () => {
        return this.api.receiveUpdates(window.location.pathname, window._communityIndexPagination).then((data) => {
            if (!data || !data.components) {
                return;
            }
            let new_state = {...this.state};
            for (const property_key of Object.keys(data)) {
                if (property_key === 'next_query_in') {
                    const old_update_timer_delay = this.update_timer_delay;
                    this.update_timer_delay = parseInt(data[property_key], 10);
                    if (this.update_timer_delay < 15000) {
                        this.update_timer_delay = 15000;
                    }
                    if (old_update_timer_delay !== this.update_timer_delay) {
                        this.registerUpdateListener(); // Re-register update timer
                    }
                    continue;
                }
                let new_value = data[property_key];
                if (property_key === 'community' && window._communityIndexPagination > 0) {
                    new_value = [];
                    let resource_ids = [];
                    for (let row of new_state[property_key]) {
                        new_value.push(row);
                        resource_ids.push(row.id);
                    }
                    for (let row of data[property_key]) {
                        if (resource_ids.indexOf(row.id) !== -1) {
                            continue;
                        }
                        new_value.push(row);
                    }
                }
                new_state[property_key] = new_value;
            }
            this.setState(new_state);
        });
    };

    constructor(props) {
        super(props);
        this.update_timer = null;
        this.update_timer_delay = 60000;
        this.state = {
            is_initialized: false,
            visitor: null,
            components: {},
            home: {},
            updateContext: (key, value) => {
                this.state[key] = value;
                this.setState(this.state);
            },
        };
        window.WebsiteApi = Comlink.wrap(new Worker(new URL('./WebsiteApi.js',import.meta.url)));
        this.api = window.WebsiteApi;
        this.acquireSession();
        this.receiveUpdates();
        this.updateImageSliders();
        window._forceUpdate = () => {
            return this.receiveUpdates();
        };
        window._forceSessionUpdate = (user_data) => {
            this.updateSession(user_data);
        };
        window._forceSessionRefresh = () => {
            return this.acquireSession();
        };
        let _prevPageUri = null;
        window._allowEdit = false;
        window._onPageChange = (component, uri, allow_edit) => {
            let page_metadata = {};
            if (component?.getPageMetadata) {
                page_metadata = component.getPageMetadata();
            }
            window._setPageMeta(page_metadata);
            window._allowEdit = allow_edit;

            if (_prevPageUri !== uri) { // _prevPageUri !== null &&
                window._forceUpdate(); // Force to reload page related updates when page URI changes
                setTimeout(() => {
                    if (uri === '/') {
                        // Ignore scrolling on homepage
                        return;
                    }
                    window.scrollTo({
                        top: 0,
                        behavior: 'smooth'
                    });

                    if (window.gtag) {
                        window.gtag("event", "page_view", {
                            page_path: location.pathname + location.search,
                        });
                    }
                }, 200);
            }
            _prevPageUri = uri;
        };
        window._setPageMeta = (metadata) => {
            const updateOgProperty = (property, value) => {
                let ogMetaTag = document.querySelector('meta[property="og:' + property + '"]');
                if (!ogMetaTag) {
                    // Insert if missing
                    ogMetaTag = document.createElement('meta');
                    ogMetaTag.setAttribute('property', `og:${property}`);
                    ogMetaTag.setAttribute('content', value);
                    document.getElementsByTagName('head')[0].appendChild(ogMetaTag);
                    return;
                }
                if (ogMetaTag.getAttribute('content') === value) {
                    // Not changed
                    return;
                }
                ogMetaTag.setAttribute('content', value);
            };
            const updateCanonicalUrl = (value) => {
                let linkCanonicalTag = document.querySelector('link[rel="canonical"]');
                if (!linkCanonicalTag) {
                    // Insert if missing
                    linkCanonicalTag = document.createElement('link');
                    linkCanonicalTag.setAttribute('rel', 'canonical');
                    linkCanonicalTag.setAttribute('href', value);
                    document.getElementsByTagName('head')[0].appendChild(linkCanonicalTag);
                    return;
                }
                if (linkCanonicalTag.getAttribute('href') === value) {
                    // Not changed
                    return;
                }
                linkCanonicalTag.setAttribute('href', value);
            };
            const updateOembedRelAlternativeUrl = (page_url) => {
                const value = `/api/website-api.php?action=get:oembed&url=${encodeURIComponent(page_url)}`
                let linkOembedRelAlternativeTag = document.querySelector('link[rel="alternate"][type="application/json+oembed"]');
                if (!linkOembedRelAlternativeTag) {
                    // Insert if missing
                    linkOembedRelAlternativeTag = document.createElement('link');
                    linkOembedRelAlternativeTag.setAttribute('rel', 'alternate');
                    linkOembedRelAlternativeTag.setAttribute('type', 'application/json+oembed');
                    linkOembedRelAlternativeTag.setAttribute('href', value);
                    document.getElementsByTagName('head')[0].appendChild(linkOembedRelAlternativeTag);
                    return;
                }
                if (linkOembedRelAlternativeTag.getAttribute('href') === value) {
                    // Not changed
                    return;
                }
                linkOembedRelAlternativeTag.setAttribute('href', value);
            };
            const updateMetaByName = (name, value) => {
                let metaTag = document.querySelector('meta[name="' + name + '"]');
                if (!metaTag) {
                    // Insert if missing
                    metaTag = document.createElement('meta');
                    metaTag.setAttribute('name', name);
                    metaTag.setAttribute('content', value);
                    document.getElementsByTagName('head')[0].appendChild(metaTag);
                    return;
                }
                if (metaTag.getAttribute('content') === value) {
                    // Not changed
                    return;
                }
                metaTag.setAttribute('content', value);
            };
            const updateMetaByProperty = (property, value) => {
                let metaTag = document.querySelector('meta[property="' + property + '"]');
                if (!metaTag) {
                    // Insert if missing
                    metaTag = document.createElement('meta');
                    metaTag.setAttribute('property', property);
                    metaTag.setAttribute('content', value);
                    document.getElementsByTagName('head')[0].appendChild(metaTag);
                    return;
                }
                if (metaTag.getAttribute('content') === value) {
                    // Not changed
                    return;
                }
                metaTag.setAttribute('content', value);
            };

            const page_url = `https://${window.location.hostname}${window.location.pathname}`; // Set URL without query parameters
            updateOgProperty('locale', 'fi_FI');
            updateOgProperty('site_name', 'Pelaajat.com');
            updateMetaByProperty('article:publisher', 'https://www.facebook.com/Pelaajatcom/');
            updateOgProperty('url', page_url);
            updateOembedRelAlternativeUrl(page_url);
            updateCanonicalUrl(page_url);
            const clean_page_title = (metadata?.title ? metadata.title : 'Suomen suurin e-urheilumedia');
            const page_title = clean_page_title + ' - Pelaajat.com';
            const page_desc = metadata?.ogDesc ? metadata?.ogDesc : 'Pelaajat.com tarjoaa sinulle hermeettiset pelilähetykset ja tuoreimmat uutiset e-urheilusta';
            if (document.title !== page_title) {
                // Page title has changed
                document.title = page_title;
            }
            updateOgProperty('title', clean_page_title);
            let img_url;
            if (!metadata?.img) {
                img_url = websiteBaseUrl + 'pelaajat-esports.jpg';
                metadata.imgWidth = 750;
                metadata.imgHeight = 393;
            } else {
                img_url = metadata.img;
            }
            const thumb_img_url = (metadata?.thumb ? metadata.thumb : websiteBaseUrl + 'pelaajat-esports.jpg');
            if (img_url && metadata?.imgWidth) {
                updateOgProperty('image:width', metadata.imgWidth);
            } else {
                updateOgProperty('image:width', "");
            }
            if (img_url && metadata?.imgHeight) {
                updateOgProperty('image:height', metadata.imgHeight);
            } else {
                updateOgProperty('image:height', "");
            }
            updateOgProperty('image', img_url);
            updateOgProperty('image:type', Tools.getImageMimeTypeFromUri(img_url));
            updateOgProperty('type', metadata?.ogType ? metadata?.ogType : 'article');
            if (metadata?.excludeFromIndex === true) {
                Tools.declarePageNoIndexToRobots();
            }
            updateOgProperty('description', page_desc);
            updateMetaByName('description', page_desc);
            const keyword_base = ["esports", "esportsfi", "eurheilu", "pelaaja", "pelaaminen", "pelit"];
            let categories = [];
            for (let category of (metadata?.categories || [])) {
                if (categories.indexOf(category) !== -1) { // Remove duplicates
                    continue;
                }
                categories.push(category.title);
            }
            for (let base_category of keyword_base) {
                if (categories.indexOf(base_category) !== -1) { // Remove duplicates
                    continue;
                }
                if (categories.length > 10) {
                    // Do not spam with keywords
                    break;
                }
                categories.push(base_category);
            }
            const keywords = categories.join(', ');

            const updateLdJson = () => {
                const media_id = `${page_url}#primaryimage`;
                const ld_type = metadata?.ldType ? metadata?.ldType : "WebPage";
                let ld_json = {
                    "@type": ld_type,
                    "@id": page_url,
                    "isPartOf": {
                        "@id": "https://pelaajat.com/",
                    },
                    "mainEntityOfPage": page_url,
                    "headline": clean_page_title,
                    "description": page_desc || null,
                    "alternativeHeadline": page_desc || null,
                    "url": page_url,
                    "thumbnailUrl": thumb_img_url,
                    "primaryImageOfPage": {"@id": media_id},
                    "associatedMedia": {"@id": media_id},
                    "image": [],
                    //"articleSection": categories[0],
                    "author": [{"@type": "Person", "name": metadata?.authorName}],
                    "creator": [metadata?.authorName],
                    "publisher": {
                        "@type": "Organization",
                        "name": "Pelaajat.com",
                        "slogan": "Suomen suurin e-urheilumedia",
                        "legalName": "Pelaajatcom esports Oy",
                        "logo": (websiteBaseUrl && websiteBaseUrl !== '/' ? websiteBaseUrl : 'https://pelaajat.com/') + 'pelaajat-esports.jpg',
                        "url": "https://pelaajat.com/",
                        "sameAs": [
                            'https://www.youtube.com/Pelaajat',
                            'https://www.facebook.com/Pelaajatcom/',
                            'https://www.instagram.com/pelaajatcom/',
                            'https://x.com/pelaajatcom',
                            'https://www.twitch.tv/pelaajatcom'
                        ]
                    },
                    "keywords": categories
                };
                if (metadata?.authorName) {
                    updateMetaByName('author', metadata?.authorName);
                }
                if (metadata?.authorLd) {
                    ld_json['author'] = metadata.authorLd;
                }
                if (metadata?.publishedTs) {
                    const publishedIsoStr = new Date(metadata.publishedTs * 1000).toISOString();
                    ld_json['datePublished'] = publishedIsoStr;
                    updateMetaByProperty('article:published_time', publishedIsoStr);
                }
                if (metadata?.createdTs) {
                    const createdIsoStr = new Date(metadata.createdTs * 1000).toISOString();
                    ld_json['dateCreated'] = createdIsoStr;
                    if (!metadata?.publishedTs) { // Publish time not known -> use creation time
                        ld_json['datePublished'] = createdIsoStr;
                    }
                    if (!metadata?.updatedTs) {
                        ld_json['dateModified'] = createdIsoStr;
                        updateMetaByProperty('article:modified_time', createdIsoStr);
                    }
                }
                if (metadata?.updatedTs) {
                    const updatedIsoStr = new Date(metadata.updatedTs * 1000).toISOString();
                    ld_json['dateModified'] = updatedIsoStr;
                    updateMetaByProperty('article:modified_time', updatedIsoStr);
                }
                let breadcrumb;
                if (ld_json['@type'] === 'NewsArticle' || ld_json['@type'] === 'Article') {
                    delete ld_json['primaryImageOfPage'];
                    ld_json['mainEntityOfPage'] = page_url;
                    ld_json['potentialAction'] = [
                        {
                            "@type": "ReadAction",
                            "target": [page_url]
                        }
                    ];
                    breadcrumb = {
                        "@type": "BreadcrumbList",
                        "@id": `${page_url}#breadcrumb`,
                        "itemListElement": [
                            {
                                "@type": "ListItem",
                                "position": 1,
                                "name": "Kotisivu",
                                "item": "https://pelaajat.com/"
                            },
                            {
                                "@type": "ListItem",
                                "position": 2,
                                "name": "Uutiset",
                                "item": "https://pelaajat.com/uutiset"
                            },
                            {
                                "@type": "ListItem",
                                "position": 3,
                                "name": clean_page_title,
                                "item": `${page_url}`
                            }
                        ]
                    };
                }
                if (img_url) {
                    ld_json['image'].push(img_url)
                }
                let ld_graph = [ld_json];
                if (breadcrumb) {
                    ld_graph.push(breadcrumb);
                }
                if (img_url) {
                    ld_graph.push({
                        "@type": "ImageObject",
                        "@id": media_id,
                        "url": img_url,
                        "contentUrl": img_url,
                        "representativeOfPage": true,
                        "width": (metadata.imgWidth ? `${metadata.imgWidth}` : null),
                        "height": (metadata.imgHeight ? `${metadata.imgHeight}` : null)
                    });
                }
                ld_graph.push({
                    "@type" : "WebSite",
                    "name" : "Pelaajat.com",
                    "alternateName" : ["PelCom", "pelaajatcom", "Pelaajatcom esports Oy"],
                    "description": "Pelaajat.com tarjoaa sinulle hermeettiset pelilähetykset ja tuoreimmat uutiset e-urheilusta",
                    "url" : "https://pelaajat.com/"
                });
                ld_json = {"@context": "https://schema.org", "@graph": ld_graph};
                const ld_json_str = JSON.stringify(ld_json);
                let scriptElement = document.querySelector('script[type="application/ld+json"]');
                if (!scriptElement) {
                    // Insert if missing
                    scriptElement = document.createElement('script');
                    scriptElement.setAttribute('type', 'application/ld+json');
                    scriptElement.text = ld_json_str;
                    document.querySelector('head').appendChild(scriptElement);
                } else if (scriptElement.text !== ld_json_str) {
                    // Page properties have changed, update LD JSON
                    scriptElement.text = ld_json_str;
                }
                updateMetaByName('keywords', keywords);
            };
            updateLdJson();
        };
    }

    render() {
        if (!this.state.is_initialized) {
            return (
                <Loading/>
            );
        }

        const router = createBrowserRouter([
            {
                path: "/",
                element: <Page />,
                errorElement: <ErrorPage />,
                children: [
                    {
                        index: true,
                        element: <HomePage />,
                    },
                    {
                        // Special page: combines all types that are trending
                        "path": "/yhteisö",
                        element: <CommunityIndexPage />,
                    },
                    {
                        // Special page: news index
                        "path": "/uutiset",
                        element: <NewsIndexPage />,
                    },
                    /*{
                        "path": "/pelaa",
                        element: <PlayPage index={true} />,
                    },*/
                    {
                        "path": "/kauppa",
                        element: <PelShop index={true} />,
                    },
                    {
                        "path": "/kauppa/:uri",
                        element: <PelShop />,
                    },
                    {
                        "path": "/kategoria/:uri",
                        element: <TagPage index={true} />,
                    },
                    /*{
                        "path": "/pelit",
                        element: <GamesPage index={true} />,
                    },
                    {
                        "path": "/pelit/:uri",
                        element: <GamesPage />,
                    },*/
                    /*{
                        "path": "/oppaat/:uri",
                        element: <GuidesPage />,
                    },
                    {
                        "path": "/oppaat",
                        element: <GuidesPage index={true} />,
                    },*/
                    {
                        "path": "/turnaukset",
                        element: <TournamentIndexPage msg="Turnaukset" />,
                    },
                    {
                        "path": "/turnaus/:uri",
                        element: <TournamentPage index={false} />,
                    },
                    {
                        "path": "/ottelu/:uri",
                        element: <TournamentMatchPage index={false} />,
                    },
                    {
                        "path": "/joukkue/:uri",
                        element: <TournamentTeamPage index={false} />,
                    },
                    {
                        "path": "/kilpapelaaja/:uri",
                        element: <TournamentPlayerPage index={false} />,
                    },
                    {
                        "path": "/yhteisöedut",
                        element: <ArticlePage uri="/yhteisöedut" index={false} />,
                    },
                    {
                        "path": "/bootcamp",
                        element: <ArticlePage uri="/bootcamp" index={false} />,
                    },
                    {
                        "path": "/saunatilat",
                        element: <ArticlePage uri="/saunatilat" index={false} />,
                    },
                    {
                        //<Route path="/modal/:id" element={<Modal />} />
                        "path": "/pelaaja",
                        element: <UserModal />,
                    },
                    {
                        "path": "/pelaaja/avatar",
                        element: <ImageUploadModal purpose="avatar" />,
                    },
                    {
                        "path": "/pelaaja/salasana",
                        element: <UserModal view="password" />,
                    },
                    {
                        "path": "/pelaaja/email",
                        element: <UserModal view="email" />,
                    },
                    {
                        "path": "/pelaaja/tiedot",
                        element: <UserModal view="info" />,
                    },
                    {
                        "path": "/pelaaja/käyttäjänimi",
                        element: <UserModal view="username" />,
                    },
                    {
                        "path": "/pelaaja/twitch",
                        element: <UserModal view="twitch" />,
                    },
                    {
                        "path": "/pelaaja/discord",
                        element: <UserModal view="discord" />,
                    },
                    {
                        "path": "/pelaaja/heippa",
                        element: <UserModal view="logged-out" />,
                    },
                    {
                        "path": "/pelaaja/kirjaudu",
                        element: <UserModal view="login-form" />,
                    },
                    {
                        "path": "/pelaaja/:id",
                        element: <ViewUserModal />,
                    },
                    {
                        "path": "/kirjoittaja/:id",
                        element: <ViewCreatorModal />,
                    },
                    {
                        "path": "/uutiset",
                        element: <NewsPage index={true} />,
                        // errorElement: <ErrorPage />, // @todo: ADD ONE
                    },
                    {
                        "path": "/uutiset/:uri",
                        element: <NewsPage />,
                        // errorElement: <ErrorPage />, // @todo: ADD ONE
                    },
                    {
                        "path": "/sidebar/:uri",
                        element: <SidebarPreviewPage />,
                    },
                    /*{
                        "path": "/tos",
                        element: <ToSPage />,
                    },*/
                    {
                        "path": "/:uri",
                        element: <ArticlePage index={false} />,
                    },
                ],
            },
            /*{
                path: "/foo",
                element: <Page state={this.state} />,
                //errorElement: <ErrorPage />,
            },*/
        ], {"basename": websiteBaseUrl.replace(/\/$/, '')});

        return (
            <React.StrictMode>
                <AppContext.Provider value={this.state}>
                    <RouterProvider router={router} fallbackElement={<Loading/>} />
                </AppContext.Provider>
            </React.StrictMode>
        );
    }
}

export default App;
